import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Helmet } from "react-helmet-async";

import { HeroSection } from "./sections/hero";
import { TemplatesSection } from "./sections/templates";

export const HomePage = () => {
  const { i18n } = useLingui();

  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <html lang={i18n.locale} />

        <title>
          {t`MedResume`} - Elevate Your Medical Career with Expertly Crafted CVs
        </title>

        <meta
          name="description"
          content="Take control of your medical career journey with MedResume. Our intuitive CV builder empowers healthcare professionals to create standout resumes, highlighting their skills and experience. Start building your path to success in the medical field today."
        />
      </Helmet>

      <HeroSection />
      <TemplatesSection />
    </main>
  );
};
